::-webkit-scrollbar {
    width: 6px;
    background: transparent;
}
  
::-webkit-scrollbar-thumb {
    background: wheat;
    border-radius: 6px;
}

.skeleton{
    opacity: .7;
    animation: skeleton-loading;
}

.skeleton-text{
    width: 100%;
    height: 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 5px;
}

.skeleton-text:last-child{
    margin: 0;
    width: 80%;
}

@keyframes skeleton-loading{
    0%{
        background-color: hsl(200, 20%, 70%);
    }

    100%{
        background-color: hsl(200, 20%, 95%);
    }
}

*{
    scroll-behavior: smooth;
    font-family: "Comfortaa";
    line-height: 1.6;
    box-sizing: border-box;
}

body{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #222222;
    color: white;
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

.allcontent {
    width: 100%;
}

header{
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.322);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content:center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

    & .logo{
        position: absolute;
        left: 50px;
        width: 130px;
        display: flex;
        flex-direction: row;
        font-family: 'Caveat', sans-serif;
        font-weight: 100;
        font-size: 2em;
        color: wheat;
        gap: 5px;

        &::before{
            content: '<';
        }

        &::after{
            content: '>';
        }

        & span{
            font-family: 'Caveat', sans-serif;
            font-weight: 100;
            padding: 0;
            margin: 0;
            color: wheat;
            width: 1.3ch;
            overflow: hidden;
            transition: all 300ms;
            display: flex;

            &:hover{
                cursor: pointer;
                width: 5.7ch;
            }
        }
    }

    & .menu-list{
        font-family: 'Comfortaa';
        display: flex;
        text-decoration: none;
        list-style: none;
        gap: 20px;
        padding-left: 0;

        & .headerIcon{
            display: none;
        }

        & .headerText{
            cursor: pointer;
            & a{
                text-decoration: none;
                color: white;
            }

            &::before{
                content: '<';
                color: wheat;
            }

            &::after{
                content: '/>';
                color: wheat;
            }
        }
    }
}

main{
    overflow-x: hidden;
    transform-style: preserve-3d;
    
    & h1{
        padding: 0;
        margin: 0;
    }

    & .title{
        font-size: 2em;
        font-weight: 100;
        color: white;
        display: flex;
        align-items: center;
    }

    & .title::before{
        content: '<h1>';
        color: white;
        opacity: 0.3;
        margin-right: 5px;
        font-size: 0.5em;
    }

    & .title::after{
        content: '</h1>';
        color: white;
        opacity: 0.3;
        margin-left: 5px;
        font-size: 0.5em;
    }

    //home
    & .home{
        color: #131313;
        flex-direction: column;
        width: 100%;
        position: relative;
        transform-style: preserve-3d;
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -1;

        & .txt{
            font-size: 2em;
            width: 18.5ch;
            color: wheat;
        }

        & .name-for-hover{
            transition: all 1s;
            color: white;
            font-weight: 100;
            font-size: 2em;
            position: relative;
            overflow: hidden;

            &::after{
                content: '';
                visibility: hidden;
                opacity: 0;
                position: absolute;
                left: calc(100% + 30px);
                height: 100%;
                width: 100%;
                background: #222222;
                border-left: 2px solid wheat;
                animation: writeName 6s steps(14);
            }

            @keyframes writeName {
                0%{
                    visibility: visible;
                    opacity: 1;
                    left: 0;
                }

                40%, 60%{
                    opacity: 1;
                    left: calc(100% + 30px);
                }

                61%{
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        & .txt-description{
            margin-top: 9px;
            color: wheat;
            font-size: 1.2em;
            width: 30.5ch;
        }

        & .first-object{
            position: absolute;
            padding: 0;
            width: 50px;
            top: 90%;
            left: 15%;
            font-size: 6em;
            transition-property: top, left;
            transition-duration: 0.3s;
            z-index: -9;
        }
        
        & .second-object{
            position: absolute;
            padding: 0;
            width: 50px;
            top: 90%;
            right: 15%;
            font-size: 6em;
            transition-property: top, left;
            transition-duration: 0.3s;
            z-index: -9;
        }

        & .objectAnimation{
            position: absolute;
            animation: rotation 60s linear infinite alternate;
            user-select: none;
            -webkit-user-select:none;
        }
  
        & .random-shapes{
            transition-property: top, left;
            transition-duration: 0.3s;
            position: absolute;
            transform-origin:200% center;
            transform: translateZ(-5px) scale(1.5);
            font-size: 4em;
            z-index: -10;
  
            & .randomShapeAnimation{
              animation: rotation 60s linear infinite alternate;
              position: absolute;
              user-select: none;
              -webkit-user-select:none;
            }
        }
  
        @keyframes rotation {
          100% { rotate:(-360deg); }
        }
        
        & .name-for-hover:hover ~ .first-object{
            top: calc(50% + 0.1em)!important;
            left: calc(50% - 2.4em)!important;
            transform: translate(-50%, -50%)!important;
            font-size: 4em;
            rotate:none;
            animation:none;
        }

        & .name-for-hover:hover ~ .first-object .objectAnimation{
            transform: translate(-50%, -50%)!important;
            animation:none !important;
        }
        
        & .name-for-hover:hover ~ .second-object{
            top: calc(50% + 0.1em)!important;
            left: calc(50% + 2.4em)!important;
            transform: translate(-0%, -50%)!important;
            font-size: 4em;
            rotate:none;
            animation:none;
        }

        & .name-for-hover:hover ~ .second-object .objectAnimation{
            transform: translate(-0%, -50%)!important;
            animation:none !important;
        }
        
        & .name-for-hover:hover ~ .random-shapes{
          transition: all 0.3s;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%)!important;
          animation:none !important;
        }
        
        & .name-for-hover:hover ~ .random-shapes .randomShapeAnimation{
          transform: translate(-50%, -50%)!important;
          animation:none !important;
        }

        & .random-shapes:nth-child(6){
            position: absolute;
            top:105%;
            left: 35%;
        }
        & .random-shapes:nth-child(7){
            position: absolute;
            top:67%;
            left: 40%;
        }
        & .random-shapes:nth-child(8){
            position: absolute;
            top:100%;
            left: 11%;
        }
        & .random-shapes:nth-child(9){
            position: absolute;
            top:114%;
            left: 90%;
        }
        & .random-shapes:nth-child(10){
            position: absolute;
            top:83%;
            left: 76%;
        }
        & .random-shapes:nth-child(11){
            position: absolute;
            top:65%;
            left: 80%;
        }
        & .random-shapes:nth-child(12){
            position: absolute;
            top:123%;
            left: 26%;
        }
        & .random-shapes:nth-child(13){
            position: absolute;
            top:88%;
            left: 94%;
        }
        & .random-shapes:nth-child(14){
            position: absolute;
            top:40%;
            left: 78%;
        }
        & .random-shapes:nth-child(15){
          position: absolute;
          top:50%;
          left: 28%;
        }
    }
    
    //about
    & .about{
        width: 100%;
        background-color: #222222;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        padding: 20px;
        gap: 20px;

        & h1{
            margin-bottom: 10px;
        }

        & .all-contant{
            max-width: 1600px;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 40px;
            gap: 30px;
            padding-top: 50px;
            border-radius: 10px;
            background-color: hsla(0, 0%, 10%, 0.55);
            
            & .about-img{
                border-radius: 10px;
            }

            & .about-skill-info{
                display: flex;
                gap: 20px;
                flex-direction: column;
    
                & .about-container{
                    height: fit-content;
                    color: wheat;
                    position: relative;
                    font-size: 1em;
                   

                    & .about-box{
                        background-color: hsl(0, 0%, 9%);
                        position: relative;
                        padding: 20px;
                        border-radius: 5px;
                    }

                    & .about-info{
                        margin-left: 40px;
                    }
    
                    & .about-info::before{
                        content: '<p>';
                        margin-left: -40px;
                        color: white;
                        opacity: 0.3;
                    }
    
                    & .about-info::after{
                        content: '</p>';
                        margin-left: -40px;
                        color: white;
                        opacity: 0.3;
                    }
    
                    & .line::after{
                        opacity: 0.3;
                        content: '';
                        width: 0;
                        height: calc(100% - 6em);
                        position: absolute;
                        background-color: white;
                        border: 0.1px solid white;
                        bottom: 3em;
                        left: 35px;
                    }
                }
    
                & .skill-container{
                    height: fit-content;
                    width: 100%;
                    border-radius: 5px;
                    color: wheat;
                    position: relative;
                    font-size: 1em;

                    & .all-skills{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 10px;
                        border-radius: 6px;

                        & .skill-box{
                            width: 33%;
                            height: fit-content;
                            border-radius: 7px;
                            position: relative;
                            padding: 10px 20px;
                            background-color: hsl(0, 0%, 9%);
                        }

                        h3{
                            color: white;
                            font-weight: 100;
                        }

                        & h3::before{
                            content: '<h3>';
                            color: white;
                            opacity: 0.3;
                            margin-right: 5px;
                            font-size: 0.75em;
                        }
                    
                        & h3::after{
                            content: '</h3>';
                            color: white;
                            opacity: 0.3;
                            margin-left: 5px;
                            font-size: 0.75em;
                        }
                    }
    
                    & .skill-list::before{
                        content: '<ul>';
                        margin-left: -40px;
                        color: white;
                        opacity: 0.3;
                    }
    
                    & .skill-list::after{
                        content: '</ul>';
                        margin-left: -40px;
                        color: white;
                        opacity: 0.3;
                    }
    
                    & .line::after{
                        opacity: 0.3;
                        content: '';
                        width: 0;
                        height: calc(100% - 8.7em);
                        position: absolute;
                        background-color: white;
                        border: 0.1px solid white;
                        bottom: 2.3em;
                        left: 35px;
                    }
    
                    & .skill-list{
                        list-style-type: none;
                        margin-top: 0;
                        margin-bottom: 0;
                        
                        & li::before{
                            content: '<li>';
                            opacity: 0.3;
                            color: white;
                            margin-right: 5px;
                        }
    
                        & li::after{
                            content: '</li>';
                            opacity: 0.3;
                            color: white;
                            margin-left: 5px;
                        }
                    }
    
                }
            }
        }

        
    }

    //project
    & .project{
        margin-top: 70px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;

        & .all-content{
            width: 100%;
            max-width: 1600px;
            display: flex;
            padding: 40px;
            flex-direction: column;
            gap: 20px;
            position: relative;
            background-color: hsla(0, 0%, 10%, 0.55);
            border-radius: 10px;
            
            & h1{
                font-size: 2em;
                font-weight: 100;
            }

            & .projects{
                width: 100%;
                height: fit-content;
                border-radius: 5px;
                position: relative;
                display: flex;
                flex-direction: column;

                & .project-list::before{
                    content: '';
                    color: white;
                    opacity: 0.3;
                }
    
                & .project-list::after{
                    content: '';
                    color: white;
                    opacity: 0.3;
                }

                & .project-list{
                    display: flex;
                    flex-direction: column-reverse;
                    width: 100%;
                    cursor: pointer;
                    position: relative;
                    
                    & .projectCon{
                        margin: 0;
                        margin-bottom: 15px;
                        display: flex;
                        gap: 10px;
                        width: 100%;
                        border-radius: 6px;
                        flex-grow: 1.8;
                        transition: all .5s ease-in;
                        overflow: hidden;
                        z-index: 1;
                        position: relative;
                        
                        & .proj-img{
                            width: 100%;
                            height: 100%;
                            border-radius: 6px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            opacity: 0.5;
                            transition: all .5s;
                        }

                        & .view{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            text-decoration: none;
                            color: wheat;
                            padding: 5px 10px;
                            border-radius: 6px;
                            background-color: hsla(0, 0%, 10%, 0.9);
                            transition: all .5s;
                        }
                            
                        & .info{
                            position: absolute;
                            top: 20px;
                            left: 70px;
                            padding: 10px;
                            z-index: 10;
                            color: wheat;
                            display: flex;
                            flex-direction: column;
                            border-radius: 6px;
                            background-color: #1C1C1C;
                            transition: all .5s;

                            & .pro-name{
                                font-size: 1.3em;
                                color: white;
                            }

                            & .pro-lang{
                                font-size: 0.8em;
                            }
                        }
                    }
                } 
            }
        }
    } 
}

footer{
    
    //contact
    & .contact{
        
        & .contact-content{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            padding: 15px;
            margin-top: 100px;

            & .social-contact{
                width: 100%;
                display: flex;
                justify-content: space-evenly;

                & hr{
                    border: none;
                    border-left: 1px solid wheat;
                    width: 1px;
                    max-width: 1px;
                    margin: 0;
                    padding: 0;
                }
    
                & .contact-list{
                    list-style-type: none;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
    
                    & a{
                        text-decoration: none;
                        color: white;
                        font-weight: 100;
                    }
                    & i{
                        color: wheat;
                        font-size: 1em;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        
                        & span{
                            color: white;
                            font-weight: 100;
                        }
                    }
                }

                & .social{
                    display: flex;
                    gap: 20px;
    
                    & a{
                        text-decoration: none;
                        color: white;
                        font-weight: 100;
                    }

                    & i{
                        color: wheat;
                        font-size: 1em;
                        display: flex;
                        gap: 10px;
                        flex-direction: row;
                        align-items: center;
                        
                        & span{
                            color: white;
                            font-weight: 100;
                        }
                    }
                }
            }
            
            & .Copyright{
                width: fit-content;
                width: -moz-fit-content;
                font-size: 0.8em;
                color: #cacaca;

                & span{
                    color: wheat;
                }
            }
        }
    }
}

@media (pointer: fine) {
    .projectCon:hover{
        flex-grow: 10 !important;
        box-shadow: 0px 1px 29px 8px rgba(0,0,0,0.31);
        -webkit-box-shadow: 0px 1px 29px 8px rgba(0,0,0,0.31);
        -moz-box-shadow: 0px 1px 29px 8px rgba(0,0,0,0.31);
        transition: all .5s;
    }

    .projectCon:hover .proj-img{
        opacity: 1 !important;
        filter: blur(0px) !important;
        transition: all .5s;
    }

    .projectCon:hover .info{
        top: 60px !important;
        transition: all .5s;
    }

    .projectCon:hover .view{
        opacity: 0 !important;
    }
}

@media (max-width: 1600px){
    .about{
        
        & .all-contant{
            padding: 50px 20px !important;
        }
    }

    .project{
        & .all-content{
            padding: 20px !important;
            & .projects{
                & .project-info{
                    max-height: calc(100vh - 200px);
                    height: fit-content !important;
                    padding: 100px 10px 50px 10px !important;
                    
                    & .img-container{
                        max-height: calc(100vh - 400px) !important;
                        max-width: calc(100% - 20px) !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 1422px){
    .about{
        
        & .all-contant{
            flex-direction: row !important;
            align-items: center !important;
            gap: 10px !important;

            & .about-img{
                margin-bottom: -30px;
                height: 100% !important;
            }

            & .about-skill-info{
                
                & .skill-container{
                    width: calc(100%) !important;

                    & .all-skills{

                        & .skill-box{
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 1200px){
    .about{
        
        & .all-contant{
            flex-direction: column !important;
            align-items: center !important;
            gap: 40px !important;

            & .about-img{
                max-height: calc(100vh - 300px) !important;
                margin-bottom: 0 !important;
            }

            & .about-container{
                width: 100% !important;
            }

            & .skill-container{
                width: calc(100%) !important;
            }
        }
    }
}

@media (max-width: 1024px){

    .project-list{
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;
        height: calc(100vh + 400px) !important;
        max-height: calc(100vh + 1000px);
        position: relative;
        gap: 5px !important;
    }

    .projectCon{
        margin: 0;
        margin-bottom: 15px;
        display: flex;
        width: calc(100%);
        border-radius: 6px;
        flex-grow: 3 !important;
        transition: all .5s !important;
        overflow: hidden;
        z-index: 1;
        position: relative;

        & .info{
            position: absolute;
            top: 20px !important;
            left: 20px !important;
            padding: 10px;
            z-index: 10;
            color: wheat;
            display: flex;
            flex-direction: column;
            border-radius: 6px;
            background-color: #1C1C1C;
            

            & .pro-name{
                font-size: 1.3em;
                color: white;
            }

            & .pro-lang{
                font-size: 0.8em;
            }
        }
    }

    .projectEx{
        margin: 0;
        margin-bottom: 15px;
        display: flex;
        width: calc(100%);
        border-radius: 6px;
        transition: all .5s ease-in;
        overflow: hidden;
        z-index: 1;
        position: relative;
        flex-grow: 5;
        box-shadow: 0px 1px 29px 8px rgba(0,0,0,0.31);
        -webkit-box-shadow: 0px 1px 29px 8px rgba(0,0,0,0.31);
        -moz-box-shadow: 0px 1px 29px 8px rgba(0,0,0,0.31);
        
        & .proj-img{
            width: 100%;
            height: 100%;
            border-radius: 6px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transition: all .5s;
            opacity: 1;
        }

        & .view{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-decoration: none;
            color: wheat;
            padding: 5px 10px;
            border-radius: 6px;
            background-color: hsla(0, 0%, 10%, 0.9);
            transition: all .5s;
        }
        
        & .info{
            position: absolute;
            top: 20px;
            left: 20px;
            padding: 10px;
            z-index: 10;
            color: wheat;
            display: flex;
            flex-direction: column;
            border-radius: 6px;
            background-color: #1C1C1C;
            
            & .pro-name{
                font-size: 1.3em;
                color: white;
            }

            & .pro-lang{
                font-size: 0.8em;
            }
        }
    }
}



@media (max-width: 825px){
    .about{
        & .all-contant{
            & .skill-container{
                & .all-skills{
                    flex-direction: column !important;
                }
            }
        }
    }
}


@media (max-width: 670px){
    header{
        height: 60px;
        justify-content: start !important;

        & .logo{
            position: static;
            margin-left: 20px;
            width: fit-content;
            width: -moz-fit-content;

            & span{
                width: 80px !important;
            }
        }

        & .menu{
            display: none;

            & .menu-list{
                
                & .headerText{
                    display: none;
                }
            }
        }
    }

    .home{
        // margin-top: -60px;
        font-size: 0.8em;
    }

    .about{
        & .all-contant{
            padding: 15px !important;
            & .about-img{
                max-width: 408px !important;
                width: 100% !important;
            }

            & .about-skill-info{
    
                & .about-container{
                    width: 100% !important;
                }
            }

            & .skill-container{

                & .all-skills{
                    flex-direction: column !important;
                    justify-content: unset;
                }

            }
        }
    }

    .project{
        & .all-content{
            padding: 15px !important;

            & .projectCon{
                & .view{
                    display: none !important;
                }
            }
        }
    }

    .contact{
        & .contact-content{
            align-items: center;
            flex-direction: column !important;
            & hr{
                border-top: 1px solid wheat !important;
                width: 90%;
            }
            & .contact-list{
                flex-direction: column !important;
                
            }
        }
    }   
}


@media (height: 330px){
    .about{
        & .all-contant{
            & .about-img{
                max-height: calc(100vh - 10px) !important;
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .home {
        height: calc(100vh - 60px); /* Fallback to viewport height for Samsung Internet */
    }
}
